import React from 'react';

const AiSection = () => {
  return (
    <section className="bg-gradient-to-r from-dark-green to-dark-green-darker py-16">
      <div className="container mx-auto px-4 text-white">
        <h2 className="text-3xl md:text-4xl font-extrabold text-center mb-8 neon-text">
          AI-Powered Innovation for the Future of Retail and eCommerce
        </h2>
        <p className="text-center text-lg md:text-xl mb-8">
          Leverage the Power of AI Solutions for Retail Business
        </p>
        <p className="text-center text-sm md:text-base text-gray-300 mb-12">
          Our AI solutions for retail and eCommerce allow you to create a seamless customer journey, optimize operational efficiency, and uncover opportunities for growth.
        </p>

        {/* Features Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <div className="feature-card rounded-lg p-6 bg-dark-green-light hover:scale-105 transition-transform">
            <div className="text-4xl mb-4 text-green-500">
              <i className="fas fa-brain"></i>
            </div>
            <h3 className="text-lg font-semibold mb-2">Personalized Recommendations</h3>
            <p className="text-sm text-gray-300">AI-powered algorithms to suggest tailored products based on customer behavior.</p>
          </div>
          
          <div className="feature-card rounded-lg p-6 bg-dark-green-light hover:scale-105 transition-transform">
            <div className="text-4xl mb-4 text-green-500">
              <i className="fas fa-dollar-sign"></i>
            </div>
            <h3 className="text-lg font-semibold mb-2">Dynamic Pricing</h3>
            <p className="text-sm text-gray-300">Optimize pricing based on demand, competition, and trends to maximize profitability.</p>
          </div>

          <div className="feature-card rounded-lg p-6 bg-dark-green-light hover:scale-105 transition-transform">
            <div className="text-4xl mb-4 text-green-500">
              <i className="fas fa-search"></i>
            </div>
            <h3 className="text-lg font-semibold mb-2">Visual Search</h3>
            <p className="text-sm text-gray-300">Allow customers to search using images and find similar products instantly.</p>
          </div>

          <div className="feature-card rounded-lg p-6 bg-dark-green-light hover:scale-105 transition-transform">
            <div className="text-4xl mb-4 text-green-500">
              <i className="fas fa-cogs"></i>
            </div>
            <h3 className="text-lg font-semibold mb-2">Inventory Management</h3>
            <p className="text-sm text-gray-300">Forecast demand and optimize inventory levels to prevent stockouts or overstock.</p>
          </div>

          <div className="feature-card rounded-lg p-6 bg-dark-green-light hover:scale-105 transition-transform">
            <div className="text-4xl mb-4 text-green-500">
              <i className="fas fa-shield-alt"></i>
            </div>
            <h3 className="text-lg font-semibold mb-2">AI-Powered Fraud Detection</h3>
            <p className="text-sm text-gray-300">Prevent fraud in real-time with AI-driven transaction monitoring and pattern recognition.</p>
          </div>

          <div className="feature-card rounded-lg p-6 bg-dark-green-light hover:scale-105 transition-transform">
            <div className="text-4xl mb-4 text-green-500">
              <i className="fas fa-truck"></i>
            </div>
            <h3 className="text-lg font-semibold mb-2">Supply Chain Optimization</h3>
            <p className="text-sm text-gray-300">Improve delivery efficiency and reduce costs with AI-powered logistics optimization.</p>
          </div>
        </div>

        {/* Call to Action */}
        <div className="text-center mt-12">
          <a
            href="#"
            className="bg-green-500 text-white py-3 px-6 rounded-lg text-lg font-semibold hover:bg-green-600 transition-all"
          >
            Start Working with Us
          </a>
        </div>
      </div>
    </section>
  );
};

export default AiSection;
