import React from 'react';

const Services = () => {
  const services = [
    {
      title: 'Web Development',
      description: 'Custom web solutions tailored to meet your business needs and goals.',
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
          <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 3v11.25a2.25 2.25 0 002.25 2.25h7.5a2.25 2.25 0 002.25-2.25V3"></path>
        </svg>
      ),
    },
    {
      title: 'Mobile App Development',
      description: 'Innovative, scalable, and feature-rich mobile app solutions.',
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
          <path strokeLinecap="round" strokeLinejoin="round" d="M9 17l3-3 3 3M9 11h6"></path>
        </svg>
      ),
    },
    {
      title: 'UI/UX Design',
      description: 'Beautiful, user-friendly interfaces that keep your audience engaged.',
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
          <path strokeLinecap="round" strokeLinejoin="round" d="M3 12h2m4-5h6M6 7l6 6"></path>
        </svg>
      ),
    },
    {
      title: 'E-Commerce Solutions',
      description: 'Comprehensive tools to scale and optimize your online store.',
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
          <path strokeLinecap="round" strokeLinejoin="round" d="M7 9l10 5-10 5V9zm0-4v2l10 5-10 5v2l10-5z"></path>
        </svg>
      ),
    },
  ];

  return (
    <div className="bg-dark-green p-4 min-h-screen">
      <div className="max-w-7xl mx-auto h-max px-6 md:px-12 xl:px-6">
        <div className="md:w-2/3 lg:w-1/2">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 text-white">
            <path
              fillRule="evenodd"
              d="M9 4.5a.75.75 0 01.721.544l.813 2.846a3.75 3.75 0 002.576 2.576l2.846.813a.75.75 0 010 1.442l-2.846.813a3.75 3.75 0 00-2.576 2.576l-.813 2.846a.75.75 0 01-1.442 0l-.813-2.846a3.75 3.75 0 00-2.576-2.576l-2.846-.813a.75.75 0 010-1.442l2.846-.813A3.75 3.75 0 007.466 7.89l.813-2.846A.75.75 0 019 4.5zM18 1.5a.75.75 0 01.728.568l.258 1.036c.236.94.97 1.674 1.91 1.91l1.036.258a.75.75 0 010 1.456l-1.036.258c-.94.236-1.674.97-1.91 1.91l-.258 1.036a.75.75 0 01-1.456 0l-.258-1.036a2.625 2.625 0 00-1.91-1.91l-1.036-.258a.75.75 0 010-1.456l1.036-.258a2.625 2.625 0 001.91-1.91l.258-1.036A.75.75 0 0118 1.5zM16.5 15a.75.75 0 01.712.513l.394 1.183c.15.447.5.799.948.948l1.183.395a.75.75 0 010 1.422l-1.183.395c-.447.15-.799.5-.948.948l-.395 1.183a.75.75 0 01-1.422 0l-.395-1.183a1.5 1.5 0 00-.948-.948l-1.183-.395a.75.75 0 010-1.422l1.183-.395c.447-.15.799-.5.948-.948l.395-1.183A.75.75 0 0116.5 15z"
              clipRule="evenodd"
            ></path>
          </svg>
          <h2 className="my-8 text-2xl font-bold text-white md:text-4xl">Our Services</h2>
          <p className="text-gray-100">Discover our range of services designed to help you succeed.</p>
        </div>
        <div className="mt-16 grid divide-x divide-y divide-dark-green-light overflow-hidden rounded-3xl border text-gray-100 border-dark-green-light sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 lg:divide-y-0">
          {services.map((service, index) => (
            <div
              key={index}
              className="group relative bg-dark-green-darker transition hover:z-[1] hover:shadow-2xl hover:shadow-green-500/10"
            >
              <div className="relative space-y-8 py-12 p-8">
                <div className="text-white w-8 h-8">{service.icon}</div>
                <div className="space-y-2">
                  <h5 className="text-xl font-semibold text-white transition">{service.title}</h5>
                  <p className="text-gray-200">{service.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
