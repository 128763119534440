import React from 'react';

const Calltoaction = () => {
  return (
    <section className="bg-gradient-to-r from-dark-green to-dark-green-darker py-16">
      <div className="container mx-auto px-4">
        {/* Free Credits Banner */}
        <p className="text-center text-sm md:text-base text-green-500 font-semibold mb-4">
          Get <a href="#" className="underline hover:text-white-400">free credit</a> to start your project today.
        </p>

        {/* Two Column Layout */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          {/* Video Column */}
          <div className="relative w-full h-64 md:h-96 bg-black rounded-lg overflow-hidden">
            <iframe
              className="w-full h-full"
              src="https://www.youtube.com/embed/dQw4w9WgXcQ" // Replace with your video link
              title="Video"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>

          {/* Content Column */}
          <div>
            <h2 className="text-3xl md:text-4xl font-extrabold text-white mb-6">
              The new way to eCommerce starts here
            </h2>
            <p className="text-sm md:text-base text-gray-300 mb-4">
             Build Shopify App with generative AI, deploy apps fast, and optimize your business performance—all while ensuring top-notch security.
            </p>
            <p className="text-sm md:text-base text-gray-300 mb-6">
              Explore how our advanced AI solutions transform the eCommerce landscape by enhancing customer experiences, boosting efficiency, and driving growth.
            </p>

            {/* CTA Buttons */}
            <div className="flex flex-col md:flex-row gap-4">
              <a
                href="#"
                className="bg-green-500 text-white py-3 px-6 rounded-lg text-sm md:text-base font-semibold hover:bg-green-600 transition-all text-center"
              >
                Get started for free
              </a>
              <a
                href="#"
                className="bg-transparent border border-green-500 text-green-500 py-3 px-6 rounded-lg text-sm md:text-base font-semibold hover:bg-green-500 hover:text-white transition-all text-center"
              >
                Contact sales
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Calltoaction;
