import React from 'react';
import Laptop from '../assets/laptop.jpg';

function Into() {
  return (
    <div className='w-full bg-white py-16 px-4'>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-2'>
        <img className='w-[500px] mx-auto my-4' src={Laptop} alt="Tech Solutions Dashboard" />
        <div className='flex flex-col justify-center'>
          {/* Highlight Gwiksoft’s Expertise */}
          <p className='text-[#00df9a] font-bold uppercase text-sm tracking-wide'>
            Transforming Businesses with Technology
          </p>
          <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2'>
            Scalable Solutions for Your Digital Transformation
          </h1>
          <p className='text-gray-600 mt-2'>
            At Gwiksoft, we deliver cutting-edge web and mobile app development, intuitive UI/UX design, and robust eCommerce solutions. Our scalable technology empowers businesses to enhance efficiency, drive engagement, and accelerate growth in a rapidly evolving digital landscape.
          </p>
          <button className='bg-black w-[200px] rounded-md font-medium my-6 mx-auto py-3 text-[#00df9a] md:mx-0 hover:opacity-90'>
            Discover More
          </button>
        </div>
      </div>
    </div>
  );
}

export default Into;
