import React from 'react';
import { Helmet } from 'react-helmet';

function ShopifySection() {
    return (
        <section className="bg-gray-900 text-white py-16">
            <Helmet>
                {/* Title */}
                <title>Shopify Certified Partner - Custom Apps & Themes</title>

                {/* Meta Description */}
                <meta 
                    name="description" 
                    content="We are a Shopify Certified Partner offering custom Shopify apps, themes, and store development to help you create a unique and successful eCommerce experience." 
                />

                {/* Meta Keywords */}
                <meta 
                    name="keywords" 
                    content="Shopify, Shopify apps, Shopify themes, Shopify development, custom Shopify apps, Shopify certified partner, eCommerce, online store, Shopify design, Shopify experts"
                />

                {/* Open Graph Tags for social media */}
                <meta property="og:title" content="Shopify Certified Partner - Custom Apps & Themes" />
                <meta property="og:description" content="We are a Shopify Certified Partner offering custom Shopify apps, themes, and store development to help you create a unique and successful eCommerce experience." />
                <meta property="og:image" content="URL_TO_IMAGE" /> {/* Replace with image URL */}
                <meta property="og:url" content="https://yourwebsite.com" />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Shopify Certified Partner - Custom Apps & Themes" />
                <meta name="twitter:description" content="We are a Shopify Certified Partner offering custom Shopify apps, themes, and store development to help you create a unique and successful eCommerce experience." />
                <meta name="twitter:image" content="URL_TO_IMAGE" /> {/* Replace with image URL */}
            </Helmet>

            <div className="max-w-7xl mx-auto px-6 lg:px-8">
                <div className="text-center space-y-4">
                    <h2 className="text-2xl font-bold text-[#00df9a]">Shopify Certified Partner</h2>
                    <p className="text-lg">
                        We specialize in building custom Shopify apps and themes to elevate your store. Let’s create something amazing together.
                    </p>
                </div>

                <div className="mt-10 grid md:grid-cols-2 gap-8">
                    {/* Shopify Apps */}
                    <div className="bg-gray-800 p-6 rounded-lg text-center hover:bg-[#00df9a] hover:text-gray-900 transition duration-300">
                        <h3 className="text-xl font-semibold">Custom Shopify Apps</h3>
                        <p className="mt-2 text-sm">Build apps tailored to your needs for seamless Shopify integrations and enhanced functionality.</p>
                    </div>

                    {/* Shopify Themes */}
                    <div className="bg-gray-800 p-6 rounded-lg text-center hover:bg-[#00df9a] hover:text-gray-900 transition duration-300">
                        <h3 className="text-xl font-semibold">Shopify Theme Design</h3>
                        <p className="mt-2 text-sm">Get a fully responsive, visually stunning theme that perfectly matches your brand.</p>
                    </div>
                </div>

                <div className="mt-12 text-center">
                    <a href="/contact" className="bg-[#00df9a] text-gray-900 rounded-lg px-6 py-3 text-sm font-semibold hover:bg-[#00c487] transition duration-300">
                        Start Your Shopify Project
                    </a>
                </div>
            </div>
        </section>
    );
}

export default ShopifySection;
