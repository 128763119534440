import React from 'react';
import {
    FaDribbbleSquare,
    FaFacebookSquare,
    FaGithubSquare,
    FaInstagram,
    FaTwitterSquare,
} from 'react-icons/fa';

function Footer() {
    return (
        <footer className="bg-gray-900 text-gray-300 py-16">
            <div className="max-w-[1240px] mx-auto grid lg:grid-cols-3 gap-8 px-4">
                {/* Logo and Social Media */}
                <div>
                    <h1 className="text-3xl font-bold text-[#00df9a]">Gwiksoft.</h1>
                    <p className="py-4 text-sm">
                        Empower your ecommerce journey with cutting-edge tools, insightful analytics, and 24/7 support. From startups to enterprises, we make growth seamless.
                    </p>
                    <div className="flex justify-start gap-4 my-6">
                        <FaFacebookSquare size={30} />
                        <FaInstagram size={30} />
                        <FaTwitterSquare size={30} />
                        <FaGithubSquare size={30} />
                        <FaDribbbleSquare size={30} />
                    </div>
                </div>

                {/* Links Section */}
                <div className="lg:col-span-2 grid sm:grid-cols-2 md:grid-cols-4 gap-8">
                    <div>
                        <h6 className="font-semibold text-gray-400 uppercase">Solutions</h6>
                        <ul>
                            <li className="py-2 text-sm hover:text-[#00df9a]">Analytics</li>
                            <li className="py-2 text-sm hover:text-[#00df9a]">Marketing</li>
                            <li className="py-2 text-sm hover:text-[#00df9a]">Commerce</li>
                            <li className="py-2 text-sm hover:text-[#00df9a]">Insights</li>
                        </ul>
                    </div>
                    <div>
                        <h6 className="font-semibold text-gray-400 uppercase">Support</h6>
                        <ul>
                            <li className="py-2 text-sm hover:text-[#00df9a]">Pricing</li>
                            <li className="py-2 text-sm hover:text-[#00df9a]">Documentation</li>
                            <li className="py-2 text-sm hover:text-[#00df9a]">Guides</li>
                            <li className="py-2 text-sm hover:text-[#00df9a]">API Status</li>
                        </ul>
                    </div>
                    <div>
                        <h6 className="font-semibold text-gray-400 uppercase">Company</h6>
                        <ul>
                            <li className="py-2 text-sm hover:text-[#00df9a]">About Us</li>
                            <li className="py-2 text-sm hover:text-[#00df9a]">Blog</li>
                            <li className="py-2 text-sm hover:text-[#00df9a]">Careers</li>
                            <li className="py-2 text-sm hover:text-[#00df9a]">Press</li>
                            <li className="py-2 text-sm hover:text-[#00df9a]">Contact</li>
                        </ul>
                    </div>
                    <div>
                        <h6 className="font-semibold text-gray-400 uppercase">Legal</h6>
                        <ul>
                            <li className="py-2 text-sm hover:text-[#00df9a]">Privacy Policy</li>
                            <li className="py-2 text-sm hover:text-[#00df9a]">Terms of Service</li>
                            <li className="py-2 text-sm hover:text-[#00df9a]">Refund Policy</li>
                        </ul>
                    </div>
                </div>
            </div>

            {/* Footer Bottom */}
            <div className="mt-12 border-t border-gray-700 pt-6 text-center text-sm">
                <p>&copy; {new Date().getFullYear()} Gwiksoft. All rights reserved.</p>
            </div>
        </footer>
    );
}

export default Footer;
