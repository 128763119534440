
import './App.css';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Analytics from './components/Analytics';
import Into from './components/Into';
import Services from './components/Services';
import AISection from './components/AISection.jsx';
import Calltoaction from './components/Calltoaction.jsx';
import SubscriptionSection from './components/SubscriptionSection.jsx';
import ShopifySection from './components/ShopifySection.jsx';
import { Helmet } from "react-helmet"; // Import Helmet



import Newsletter from './components/Newsletter';
import Footer from './components/Footer.jsx';
// import Navbar1 from './components/Nav/Navbar1';
function App() {
  return (
    <div>
    <Helmet>
  {/* Basic SEO */}
  <title>Gwiksoft - AI-Powered Ecommerce Solutions</title>
  <meta
    name="description"
    content="Supercharge your ecommerce business with AI-powered solutions. We are a Shopify-certified partner offering apps, themes, and advanced analytics."
  />
  <meta
    name="keywords"
    content="ecommerce, shopify, react app, modern website, AI solutions, analytics, retail business"
  />
  <meta name="author" content="Gwiksoft" />

  {/* Open Graph / Social Media Sharing */}
  <meta property="og:title" content="Gwiksoft - AI-Powered Ecommerce Solutions" />
  <meta
    property="og:description"
    content="Transform your ecommerce business with AI-powered solutions and advanced analytics. Partner with us today!"
  />
  <meta property="og:image" content="https://yourwebsite.com/og-image.jpg" />
  <meta property="og:url" content="https://yourwebsite.com" />
  <meta property="og:type" content="website" />

  {/* Twitter Card */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Gwiksoft - AI-Powered Ecommerce Solutions" />
  <meta
    name="twitter:description"
    content="Supercharge your ecommerce business with AI-powered solutions and advanced analytics."
  />
  <meta name="twitter:image" content="https://yourwebsite.com/twitter-image.jpg" />

  {/* Favicon */}
  <link rel="icon" href="/favicon.ico" />
</Helmet>

   
    <Navbar/>
  <Hero/>
    <Analytics/>
    <Calltoaction />
    <Into />
    <Services />
    <ShopifySection />
    <SubscriptionSection />

    <AISection />
    <Newsletter/>
  <Footer/> 
    {/* <Navbar1/>
    <Footer/> */}

   </div>
  );
}

export default App;
