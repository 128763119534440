import React, { useEffect, useRef } from 'react';
import Typed from 'typed.js';

function Hero() {
    const typedElement = useRef(null);

    useEffect(() => {
        const typed = new Typed(typedElement.current, {
            strings: ['B2B', 'B2C', 'SaaS'],
            typeSpeed: 100,
            backSpeed: 120,
            loop: true,
        });

        return () => {
            typed.destroy(); // Clean up on component unmount
        };
    }, []);

    return (
        <div className="text-white bg-gray-900">
            <div className="max-w-[800px] mt-[-80px] w-full h-screen mx-auto text-center flex flex-col justify-center">
                {/* Highlight your eCommerce expertise */}
                <p className="text-[#00df9a] font-semibold text-sm uppercase tracking-widest mb-2">
                    Your End-to-End eCommerce Solution
                </p>
                <h1 className="md:text-5xl sm:text-4xl text-3xl font-extrabold md:py-4">
                    Empowering Your Business Online
                </h1>

                {/* Focus on flexibility and growth */}
                <div className="flex justify-center items-center flex-wrap text-center mt-2">
                    <p className="md:text-2xl sm:text-xl text-lg font-medium leading-tight">
                        Build, Scale, and Optimize your eCommerce platform for
                    </p>
                    <span
                        ref={typedElement}
                        className="md:text-2xl sm:text-xl text-lg font-semibold pl-2"
                    />
                </div>

                {/* Short explanation of the eCommerce services */}
                <p className="md:text-lg text-sm font-medium text-gray-400 mt-4">
                    We provide a full suite of eCommerce solutions to help you grow across B2B, B2C, and SaaS platforms.
                </p>
                
                {/* Call-to-action button */}
                <button className="bg-[#00df9a] w-[160px] rounded-md font-medium mt-6 mx-auto py-2 text-black transition-transform transform hover:scale-105">
                    Get Started
                </button>
            </div>
        </div>
    );
}

export default Hero;
