import React from 'react';

const SubscriptionSection = () => {
  return (
    <section className="bg-gray-50 py-16">
      <div className="container mx-auto max-w-7xl px-6 lg:px-8">
        {/* Section Header */}
        <div className="text-center mb-12">
          <h2 className="text-4xl font-extrabold text-gray-900">Monthly Pricing</h2>
          <p className="mt-4 text-lg text-gray-600">
            Choose our monthly subscription plan tailored for ecommerce platforms like Shopify, WooCommerce, Wix, Magento, and more. 
            Cancel anytime. For custom enterprise pricing, <a href="/contact" className="text-blue-500 underline">contact us here!</a>
          </p>
        </div>

        {/* Pricing Section */}
        <div className="flex flex-col lg:flex-row lg:items-start lg:space-x-8">
          {/* Features Section */}
          <div className="flex-1 p-8 lg:flex-auto bg-white rounded-3xl ring-1 ring-gray-200 shadow-md">
            <h3 className="text-2xl font-bold tracking-tight text-gray-900">What’s Included</h3>
            <p className="mt-4 text-base leading-7 text-gray-600">
              Explore the benefits of our subscription and see how we can transform your ecommerce experience.
            </p>
            <ul className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6">
              <li className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="text-green-500"
                >
                  <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4z" />
                </svg>
                <span className="ml-3">Integration with major platforms</span>
              </li>
              <li className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="text-green-500"
                >
                  <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4z" />
                </svg>
                <span className="ml-3">Unlimited product listings</span>
              </li>
              <li className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="text-green-500"
                >
                  <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4z" />
                </svg>
                <span className="ml-3">24/7 customer support</span>
              </li>
              <li className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="text-green-500"
                >
                  <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4z" />
                </svg>
                <span className="ml-3">Customizable themes and templates</span>
              </li>
              <li className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="text-green-500"
                >
                  <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4z" />
                </svg>
                <span className="ml-3">SEO and marketing tools</span>
              </li>
              <li className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="text-green-500"
                >
                  <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4z" />
                </svg>
                <span className="ml-3">Analytics and performance tracking</span>
              </li>
            </ul>
          </div>

          {/* Pricing Section */}
          <div className="lg:w-1/3 p-6 lg:mt-0 bg-gray-100 rounded-3xl ring-1 ring-gray-200 shadow-md text-center flex flex-col items-center">
            <h3 className="text-base font-semibold text-gray-600">Monthly Plan</h3>
            <div className="mt-6 mb-8 flex flex-col items-center">
              <p className="flex items-baseline text-5xl font-bold text-gray-900">
                <span className="line-through text-gray-400">$49</span>
                <span className="ml-2 text-green-500 italic">FREE*</span>
              </p>
              <p className="text-sm text-gray-600">*Limited time offer</p>
            </div>
            <a
              href="/signup"
              className="bg-blue-600 text-white py-3 px-6 rounded-lg text-sm font-semibold hover:bg-blue-700 transition-all"
            >
              Get Started →
            </a>
            <p className="mt-6 text-xs text-gray-500">Invoices and receipts available for reimbursement.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SubscriptionSection;
