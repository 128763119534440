import React from 'react';
import Laptop from '../assets/laptop.jpg';

function Analytics() {
  return (
    <div className='w-full bg-white py-16 px-4'>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-2'>
        <img className='w-[500px] mx-auto my-4' src={Laptop} alt="Analytics Dashboard" />
        <div className='flex flex-col justify-center'>
          {/* Highlight the unique offering */}
          <p className='text-[#00df9a] font-bold uppercase text-sm tracking-wide'>
            Centralized eCommerce Insights
          </p>
          <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2'>
            Unlock Actionable Insights for Your Business
          </h1>
          <p className='text-gray-600 mt-2'>
            Gain a competitive edge with our intuitive analytics dashboard. Track sales, monitor trends, and optimize customer experiences—all from one platform. Our tools make data-driven decision-making simple and effective, helping you grow faster.
          </p>
          <button className='bg-black w-[200px] rounded-md font-medium my-6 mx-auto py-3 text-[#00df9a] md:mx-0 hover:opacity-90'>
            Learn More
          </button>
        </div>
      </div>
    </div>
  );
}

export default Analytics;
